.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #e75600; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 10px;
    animation: spin 1.5s linear infinite;
    margin-left: 45%;
    margin-top: 45%;
  }
  .wrapper {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin: auto;
    display: block;
    position: relative;
    animation: fadeIn 1s;
    object-fit: cover;
    max-width: 600px;
    background-size: cover;
  }
  .businessname {
    vertical-align:middle;
    text-align: center;
    animation: fadeIn 2s;
    font-weight: bold;
    font-size: 19px;
    font-family: Lexend Deca;
  
  }
  .busname{
    animation: fadeIn 2s;
    font-weight: bold;
    font-size: 12px;
    font-family: Lexend Deca;
    
  }
  .costbreakdown{
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    font-size: 10px;
    font-family: 'Lexend Deca';
    margin-right: 20px;

  }
  .itemimages{
    max-width: 600px;
    text-align: center;
    margin-top: 20px;
    margin: 10px auto;
    justify-content: center;
    align-content: center;
    display: block;
  }
  
  .businessaddress{
    vertical-align:middle;
    text-align: center;
    animation: fadeIn 2s;
    font-size: 12px;
    font-family: Lexend Deca;
    margin:3px
  }
  .busaddress{
    animation: fadeIn 2s;
    font-size: 10px;
    font-family: Lexend Deca;
    margin:3px
  }
  .order-info{
    background-color: '#fff4d4';
    border-radius:20;
    margin:5;
    width:75%
  }
.business-info {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Align items vertically */
  margin-top: 5px;
}

.image-container {
  margin-right: 10px; /* Add some spacing between the image and text */
}

.person-image {
  width: 60px; /* Set the width of the image */
  height: 60px; /* Maintain aspect ratio */
  margin-left: 2px;
}
.orderitemimage{
  width: 60px; /* Set the width of the image */
  height: 60px; /* Maintain aspect ratio */
  margin-left: 2px;
  margin-top: 5px;
  border-radius: 10px;
  text-align: left;
}
.text-container {
  display: flex; /* Use flexbox layout for text container */
  flex-direction: column; /* Stack name and address vertically */
}

/* Optional styling for name and address */
.text-container h2, .text-container p {
  margin: 0; /* Remove default margin */
}
  .businessweb {
    transition-property: background-color, font-size, transform, color;
    transition-timing-function: ease-in-out;
    transition-duration: 2s;
    height: 20px;
    background-color: #e75600;
    vertical-align:middle;
    text-align: center;
    color: white;
    font-family: Lexend Deca;
    font-size: 14;
    text-decoration:none;
    width: 45%;
    border-radius: 20px;
    margin-left: 28%;
    animation: fadeIn 2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
    
  }
  .weburl{
    margin-top: -5px;
    margin-left: 2px;
    vertical-align:middle;
    height: 20px;
    width: 20px;
    transition-property: background-color, font-size, transform, color;
    transition-timing-function: ease-in-out;
    transition-duration: 2s;
    
    animation: fadeIn 2s;
  }
  .button{
    background-color: #e75600;
    vertical-align:middle;
    text-align: center;
    color: white;
    text-decoration:none;
    width: 40%;
    border-radius: 20px;
    margin-left: 25%;
    animation: fadeIn 2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
  }
  .inputs{
    vertical-align:middle;
    display: flex;
  justify-content: center;
  margin-top: 15px;
  }
  .maindiv{
    background-color: white;
    opacity: 1;
      overflow-y: scroll;
      position:fixed;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
      z-index:1000;
  }
  .footer{
    position: fixed;
bottom: 0;
background-color: #e75600;
text-align: center;
padding: 10px;
width: 48%;
font-size: 14px;
color: white;
border-top-left-radius: 8px;
border-top-right-radius: 8px;
font-weight: bold;
left: 50%;
    transform: translateX(-50%);
  }
  .textmessage{
    font-family :Lexend Deca;
    font-size: 14;
  }
  .profilewrap {
    border-radius: 50%;
    height: 120px;
    width:120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: -50px;
    position: relative;
    animation: fadeIn 2s;
    border-color: white;
    background-color:white;
    object-fit: cover;
    
  }
  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
  }
  
  .video-player {
    max-width: 600px;
    width: 100%; /* Ensure responsiveness */
  }
  .headerimage{
    height: 45px;
    width: 45px;
    margin-left: 5px;
    margin-top: 3px;
  }
  .userimage{
    margin-top: 2px;
    margin-left: 2px;
    vertical-align:middle;
    height: 20px;
    width: 20px;
    transition-property: background-color, font-size, transform, color;
    transition-timing-function: ease-in-out;
    transition-duration: 2s;
    
    animation: fadeIn 2s;
  }
  .header{
    border-bottom-style: inset;
    height: 50px;
  }
  .headertext{
    margin-top: -32px;
    margin-left: 55px;
    font-weight: bold;
    text-decoration: none;
    font-family: "DM Serif Text";
  }
  .orderheader{
    border-bottom-style: inset;
    height: 25px;
  }
  .storelinks {
    position: relative;
    margin-left: 35%;
    
  }
  .joincommunitystorelinks{
    position: relative;
    margin-left: 35%;
  }
  .businesssignature {
    margin-left: 10px;
    margin-top: 20px;
    white-space: pre-wrap;
    font-weight: bold;
    font-size: 14;
    
  }
  .modal-contents{
    top: '50%';
            left: '50%';
            right: 'auto';
            bottom: 'auto';
            margin-right: '-50%';
            transform: 'translate(-50%, -50%)';
            width: '80vw';
            height: '80vh';
            border: 'none';
            box-shadow: '0px 4px 20px rgba(0, 0, 0, 0.3)';
            z-index: 1000;
            overflow: 'auto';
  }
  .disountmodalimage{
    width: '65%';
    height: '55%'; 
    margin-left: '15%'; 
    object-fit: 'contain';
  }
  .translation{
   
    margin-left: 10px;
   
  }
  .sign{
    white-space:pre-wrap;
  }
  .playstore {
    margin-left: -14px;
    
  }
  .appstore {
    margin-left: 20px;
    width: 110px;
    height: 35px;
  
  }
  
  
  .load{
    vertical-align: middle;
    align-items: center;
    align-self: center;
    justify-content: center;
  }
  .content {
    width: 50%;
    margin-left: 25%;
    vertical-align: middle;
    animation: fadeIn 2s;
  }
  .orderpagecontent {
    width: 400px;
    margin: 0 auto;
    vertical-align: middle;
    animation: fadeIn 2s;
}

  .joincommunitycontent{
    width: 50%;
    margin-left: 25%;
    vertical-align: middle;
    animation: fadeIn 3s;
    font-family: "DM Serif Text";
  }
  .category-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    vertical-align: middle;
    justify-content: center;
    margin-top: 5px;
    margin: 5px auto; /* Adjusted margin for centering */
    max-width: 500px;
  }
  
  .category {
    background-color: lightgray;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 12px;
  }
  .description{
    vertical-align:middle;
    text-align:justify;
    animation: fadeIn 2s;
    font-size: 12px;
    font-family: Lexend Deca;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;

  }
  .subcategory {
    background-color: black;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 12px;
    color: lightgray;
  }
  .offercontainer {
    display: flex;
    flex-wrap:nowrap;
    justify-content: center;
    gap: 5px;
    margin-top: 5px;
    margin-left: 35%;
    margin-right: 35%;
  }
  .discountmodaltext{
    font-size: 12px;
    font-weight: bolder;
    text-align: right;
    margin-left: 10px;
    display: block;
  }
  .card {
    min-width: 100px; /* Adjust width as needed */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;

    
  }
  
  .card h3 {
    margin: 0;
  }
  
  .card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .offerimage{
    margin-left: 25%;
    margin-right: 25%;
    width: 55px;
    height: 65px;
    object-fit: contain;
    margin-top: 2%;
  }
  .joinbutton{
    transition-property: background-color, font-size, transform, color;
    transition-timing-function: ease-in-out;
    transition-duration: 2s;
    height: 23px;
    background-color: #e75600;
    vertical-align:middle;
    text-align: center;
    color: white;
    font-family: Lexend Deca;
    font-size: 14;
    text-decoration:none;
    width: 44%;
    border-radius: 20px;
    margin-left: 28%;
    animation: fadeIn 2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
  }.lds-ripple {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    margin-left: 40%;
    margin-top: 50%;
    vertical-align: middle;
    
    align-items: center;
    align-self: center;
    justify-content: center;
  }
  .lds-ripple div {
    position: absolute;
    border: 8px solid #e75600;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.6, 2, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (max-width: 560px) {
    .content {
      width: 100%;
      margin-left: 0;
      overflow-x: auto;
      
    }
    .orderpagecontent {
      width: 100%;
      margin-left: 0;
      overflow-x: auto;
      
    }
    .footer{
      position: fixed;
  bottom: 0;
  background-color: #e75600;
  text-align: center;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: bold;
    }
    .modal-contents{
        width: 1%;
                border: 'none';
                object-fit: contain;

      }
      .itemimages{
      
        max-width: 100%;
  height: auto;
  display: block; /* Ensures the image is centered */
  margin:  auto;
  margin-top: 10px;
      }
    .disountmodalimage{
        width: '15%';
        height: '55%'; 
        margin-left: '15%'; 
      }
    .joincommmunitycontent {
      width: 100%;
      margin-left: 0;
      
      
    }
    .order-info{
      background-color: '#fff4d4';
      border-radius:20;
      margin:5;
    }
    .category-container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      vertical-align: middle;
      justify-content: center;
      margin-top: 5px;
    }
    .offercontainer {
       
        
        max-width: 50%;
      }
      .offer{
        display: flex;
        flex-wrap:nowrap;
        justify-content: center;
        gap: 5px;
        margin-top: 5px;
        max-width: 100%;
        display: inline-block;
      }
     
      .card {
        min-width: 100px; /* Adjust width as needed */
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 10px;
        flex-shrink: auto;
    
        
      }
      
      .card h3 {
        margin: 0;
      }
      
      .card img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
      
    .lds-ripple{
      margin-left: 32%;
      margin-top: 50%;
    }
    .joinbutton{
      width:64%;
      margin-left:18%
    }
    .storelinks {
      margin-left: 30%;
  
    }
    .joincommunitystorelinks{
      margin-left: auto;
  
    }
  .playstore {
    margin-left: -15px;
    
  }
  .appstore {
    margin-left: 5px;
    width: 90px;
    height: 30px;
  
  }
  .weburl{
    margin-top: -2px;
    margin-left: 1px;
    vertical-align:middle;
    height: 16px;
    width: 16px;
    transition-property: background-color, font-size, transform, color;
    transition-timing-function: ease-in-out;
    transition-duration: 2s;
margin-right: 3px;
    animation: fadeIn 2s;
  }
  .userimage{
    margin-top: -1px;
    margin-left: 2px;
    vertical-align:middle;
    height: 19px;
    width: 19px;
    transition-property: background-color, font-size, transform, color;
    transition-timing-function: ease-in-out;
    transition-duration: 2s;
    
    animation: fadeIn 2s;
  }
    .wrapper {
      width: 100%;
      height:auto;
      flex-wrap: wrap;
      margin: auto;
      display: block;
      position: relative;
      object-fit: cover;
      min-width: 100%;
    }
  }
  